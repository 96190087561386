<template>
	<div class="detail-transfer-page-table-list">
		<table class="detail-transfer-page-table-list__table">
			<thead class="detail-transfer-page-table-list__table__head">
				<tr>
					<th v-for="(item, index) in tableHead" :key="index">
						{{ item }}
					</th>
				</tr>
			</thead>
			<tbody>
				<slot />
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	name: 'DetailTransferListTable',
	props: {
		tableHead: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style
	src="./DetailTransferListTable.scss"
	lang="scss"
	scoped
></style>