<template>
  <div class="detail-transfer-page">
    <div class="detail-transfer-page__title">
      <div class="title-section flex">
        <router-link
          :to="{ name: 'payment-disbursement-approval' }"
          class="detail-transfer-page__title__link"
        >
          <bg-icon
            name="back"
            size="lg"
            class="detail-transfer-page__title__icon"
          />
        </router-link>
        <bg-text class="detail-transfer-page__title__text" size="heading-2">
          Detail Transfer Pendapatan
        </bg-text>
      </div>
      <div class="title-right">
        <bg-button-icon
          v-if="!loading.disbursementDetail && showRecalculateButton"
          icon="reload"
          size="sm"
          icon-size="sm"
          @click="recalculateData"
        >
          Refresh Halaman ini
        </bg-button-icon>
      </div>
    </div>

    <div v-if="loading.disbursementDetail">
      <bg-skeleton width="100%" :height="275" />
    </div>
    <div v-else>
      <detail-transfer-info :data="detailRincian" />
    </div>

    <div class="detail-transfer-page__rincian">
      <bg-text size="heading-3" class="detail-transfer-page__rincian__title"
        >Rincian Penjualan Kamar</bg-text
      >
      <div v-if="loading.disbursementDetail">
        <bg-skeleton width="100%" :height="50" />
      </div>
      <div v-else>
        <div class="detail-transfer-page__rincian__desc split">
          <p class="detail-transfer-page__rincian__desc__text">
            Data tagihan penyewa yang terbayar dan masuk pada periode cut off
          </p>
        </div>
        <div v-if="invoice.is_hybrid" class="mb-20">
          <bg-tabs v-model="invoiceActiveTabIndex">
            <bg-tab
              v-for="tab in invoiceTabs"
              :key="tab.id"
              :id="tab.id"
              :title="tab.title"
            />
          </bg-tabs>
        </div>
        <div class="invoice-interaction mb-24">
          <div class="search-invoice">
            <bg-input
              icon="search"
              placeholder="Cari invoice atau penyewa"
              size="md"
              disabled
            />
            <bg-button variant="primary" class="ml-16" disabled>Cari</bg-button>
          </div>
          <div>
            <bg-button
              v-if="accessEditTransactionDisbursement"
              variant="secondary"
              :disabled="!isUnapproved"
              @click="
                $router.push(
                  `/disbursement/add-manual-transaction/${currentApprovalId}`
                )
              "
            >
              Tambahkan Transaksi
            </bg-button>
          </div>
        </div>
      </div>

      <disbursement-invoice-list
        :data="selectedInvoiceList"
        :loading="loading.getInvoice"
        :is-unapproved="isUnapproved"
        @emit-edit-transaction="handleEditTransaction"
        @emit-go-to-additional-cost="handleGoToAdditionalCost"
        @emit-handle-delete-transaksi="handleDeleteTransaksi"
      />
    </div>

    <div v-if="data.ota.is_ota_available">
      <div>
        <bg-text size="heading-3" class="detail-transfer-page__rincian__title">
          Rincian Penjualan Kamar Harian OTA
        </bg-text>
      </div>

      <template v-if="loading.ota">
        <bg-skeleton
          width="100%"
          height="50px"
          data-testid="ota-selling-loader"
          class="mb-24"
        />
      </template>

      <template v-else>
        <detail-transfer-list-table
          :table-head="table_header.ota"
          class="table-header-ota"
        >
          <template v-if="data.ota.data.length">
            <tr
              v-for="item in data.ota.data"
              :key="`ota-${item.id}`"
              :data-testid="`ota-item-${item.id}`"
            >
              <td>{{ item.tenant_name }}</td>
              <td>
                <p class="mb-4">{{ item.room_number }}</p>
                <p class="color-grey">{{ item.room_type }}</p>
              </td>
              <td>{{ dateFormatter(item.check_in_date, true) }}</td>
              <td>{{ dateFormatter(item.check_out_date, true) }}</td>
              <td>{{ item.booking_id }} ({{ item.vendor }})</td>
              <td class="ta-r">{{ rupiahFormatter(item.amount) }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6" class="ta-c">Belum Ada Penyewa Harian</td>
            </tr>
          </template>
          <tr>
            <td colspan="4"></td>
            <td colspan="1">
              <bg-text size="label-3">Total Penjualan Kamar Harian</bg-text>
            </td>
            <td colspan="1" class="ta-r">
              <bg-text size="label-3">{{
                rupiahFormatter(sumTotalOta)
              }}</bg-text>
            </td>
          </tr>
        </detail-transfer-list-table>
      </template>
    </div>

    <div class="detail-transfer-page__tenant">
      <div>
        <bg-text size="heading-3" class="detail-transfer-page__rincian__title">
          Biaya Tambahan Penyewa
        </bg-text>
      </div>

      <template v-if="loading.additionalTenantCost">
        <bg-skeleton
          width="100%"
          height="50px"
          data-testid="biaya-tambahan-loader"
          class="mb-24"
        />
      </template>
      <template v-else>
        <detail-transfer-list-table
          class="additional-tenant-cost-table"
          :table-head="table_header.tenant"
        >
          <template v-if="data.additionalTenantCostList.length">
            <tr
              v-for="item in data.additionalTenantCostList"
              :key="`tenant-cost-${item.id}`"
              :data-testid="`additional-tenant-cost-item-${item.id}`"
            >
              <td>
                <bg-text size="title-5">{{ item.tenant_name }}</bg-text>
                <bg-text size="body-2 bg-u-mt-xxs">
                  <span class="text-muted">Kamar</span>
                  {{ item.room_number }}
                </bg-text>
              </td>
              <td>
                <bg-text size="title-5">
                  {{ item.cost_name || '-' }}
                </bg-text>
                <bg-text
                  size="body-2 bg-u-mt-xxs"
                  class="text-muted"
                >
                  {{ item.description_duration }}
                </bg-text>
              </td>
              <td>
                <bg-text size="body-2">{{ invoiceType[item.type] }}</bg-text>
              </td>
              <td>
                <bg-link
                  v-if="item.url_invoice"
                  :href="`${item.url_invoice}`"
                  target="_blank"
                  rel="noopener"
                  class="blue-link"
                >
                  {{ item.invoice_number }}
                </bg-link>
                <span v-else>
                  {{ item.invoice_number || '-' }}
                </span>
              </td>
              <td>
                <bg-text size="body-2">
                  {{ dateFormatter(item.start_period) }}
                </bg-text>
              </td>
              <td>
                <bg-text size="body-2">
                  {{ dateFormatter(item.end_period) }}
                </bg-text>
              </td>
              <td>
                <bg-text size="body-2">
                  {{ item.total_transferred | rupiah }}
                </bg-text>
              </td>
              <td>
                <table-action-menu>
                  <bg-list-item @click="() => {}">
                    Ubah Biaya Tambahan
                  </bg-list-item>
                  <bg-list-item
                    v-if="
                      item.type === 'manual' || item.type === 'manual_invoice'
                    "
                    :clickable="item.type === 'manual_invoice' && isUnapproved"
                    :class="{
                      disabled: item.type === 'manual' && !isUnapproved,
                    }"
                    :data-testid="`delete-additional-cost-${item.id}`"
                    @click="handleOpenDeleteAdditionalCostModal(item.id)"
                  >
                    Hapus Biaya Tambahan
                  </bg-list-item>
                </table-action-menu>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="8" class="ta-c">Belum Ada Biaya Tambahan Penyewa</td>
            </tr>
          </template>
          <tr>
            <td colspan="2">
              <bg-text>Total Biaya Tambahan Penyewa</bg-text>
            </td>
            <td colspan="4"></td>
            <td colspan="2">
              <bg-text>{{ sumTotalAdditionalTenantCost | rupiah }}</bg-text>
            </td>
          </tr>
        </detail-transfer-list-table>
      </template>
    </div>

    <div class="detail-transfer-page__pengurangan">
      <div>
        <bg-text size="heading-3" class="detail-transfer-page__rincian__title">
          Biaya Lainnya
        </bg-text>
        <div class="detail-transfer-page__rincian__desc split">
          <p class="detail-transfer-page__rincian__desc__text">
            Data biaya lainnya bagi properti pada periode cut off
          </p>
        </div>
      </div>

      <template v-if="loading.operationalCost">
        <bg-skeleton width="100%" height="50px" class="mb-24" />
      </template>
      <template v-else>
        <div class="flex align-center justify-space-between mb-24">
          <bg-text>Biaya Pengurangan</bg-text>
          <bg-button
            v-if="accessEditTransactionDisbursement"
            data-testid="button-add-reduction"
            variant="secondary"
            :disabled="!isUnapproved"
            @click="handleOpenOperationalModal({})"
          >
            Tambahkan
          </bg-button>
        </div>
        <detail-transfer-list-table
          :table-head="table_header.operasional"
          data-testid="table-pengurangan"
        >
          <template v-if="data.costList.length">
            <tr
              v-for="item in data.costList"
              :key="`cost-list-${item.cost_id}`"
            >
              <td class="ta-l">
                {{
                  (item.type === 'not_in_mamipay' ? 'Potong Komisi ' : '') +
                  item.name
                }}
              </td>
              <td>
                {{
                  item.type === 'property_expense_detail'
                    ? '-'
                    : item.amount | rupiah
                }}
              </td>
              <td>{{ item.qty ? `${item.qty}x` : '-' }}</td>
              <td>
                {{ item.total_amount | rupiah }}
              </td>
              <td>
                <table-action-menu v-if="accessEditTransactionDisbursement">
                  <bg-list-item
                    :clickable="
                      isUnapproved &&
                      item.type !== 'property_expense_detail' &&
                      item.type !== 'not_in_mamipay'
                    "
                    :class="{ disabled: !isUnapproved }"
                    @click="handleOpenOperationalModal(item)"
                  >
                    Ubah
                  </bg-list-item>
                  <bg-list-item
                    :clickable="
                      isUnapproved &&
                      item.type !== 'property_expense_detail' &&
                      item.type !== 'not_in_mamipay'
                    "
                    :class="{ disabled: !isUnapproved }"
                    @click="handleOpenDeleteOperationalModal(item.cost_id)"
                  >
                    Hapus
                  </bg-list-item>
                </table-action-menu>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="5" class="ta-c">Belum Ada Biaya Pengurangan</td>
            </tr>
          </template>
          <tr>
            <td colspan="2">
              <bg-text>Total Biaya Pengurangan</bg-text>
            </td>
            <td></td>
            <td>
              <bg-text>{{ sumTotalCost | rupiah }}</bg-text>
            </td>
            <td></td>
          </tr>
        </detail-transfer-list-table>
      </template>

      <template v-if="loading.additionalIncome">
        <bg-skeleton width="100%" height="50px" class="mb-24" />
      </template>
      <template v-else>
        <div class="flex align-center justify-space-between mb-24">
          <bg-text>Tambahan Pendapatan</bg-text>
          <bg-button
            v-if="accessEditTransactionDisbursement"
            variant="secondary"
            :disabled="!isUnapproved"
            @click="handleOpenOperationalModal({}, true)"
          >
            Tambahkan
          </bg-button>
        </div>
        <detail-transfer-list-table :table-head="table_header.income">
          <template v-if="data.additionalIncomeList.length">
            <tr
              v-for="(item, index) in data.additionalIncomeList"
              :key="`additional-income-${index}-${item.id}`"
            >
              <td style="text-align: left">
                {{ item.name }}
              </td>
              <td>
                {{ item.amount | rupiah }}
              </td>
              <td>{{ item.qty }}x</td>
              <td>
                {{ item.total_amount | rupiah }}
              </td>
              <td>
                <table-action-menu v-if="accessEditTransactionDisbursement">
                  <bg-list-item
                    :clickable="isUnapproved"
                    :class="{ disabled: !isUnapproved }"
                    @click="handleOpenOperationalModal(item, true)"
                  >
                    Ubah
                  </bg-list-item>
                  <bg-list-item
                    :clickable="isUnapproved"
                    :class="{ disabled: !isUnapproved }"
                    @click="
                      handleOpenDeleteOperationalModal(item.income_id, true)
                    "
                  >
                    Hapus
                  </bg-list-item>
                </table-action-menu>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="5" class="ta-c">
                Belum Ada Biaya Tambahan Pendapatan
              </td>
            </tr>
          </template>
          <tr>
            <td colspan="2">
              <bg-text>Total Tambahan Pendapatan</bg-text>
            </td>
            <td></td>
            <td>
              <bg-text>{{ sumTotalAdditionalIncome | rupiah }}</bg-text>
            </td>
            <td></td>
          </tr>
        </detail-transfer-list-table>
      </template>
    </div>

    <div class="detail-transfer-page__laporan">
      <bg-text size="heading-3" class="detail-transfer-page__laporan__title"
        >Rincian Transfer Pendapatan</bg-text
      >

      <partnership-model-and-add-on
        :model="detailRincian.revenue_model_name"
        :jp-type="detailRincian.income_guarantee_type"
        :adp-type="detailRincian.adp_type"
      />

      <detail-transfer-detail-income
        :data="data.revenueSection"
        :is-ota="data.ota.is_ota_available"
        :is-loading="loading.incomeTransferBreakdown"
      />
    </div>

    <template v-if="!loading.disbursementDetail">
      <bg-card class="detail-transfer-page__laporan__notes">
        <bg-grid>
          <bg-grid-item :col="9">
            <bg-text>Keterangan tambahan untuk owner</bg-text>
            <bg-text size="body-2">
              <span v-if="detailRincian.notes">{{ detailRincian.notes }}</span>
              <i v-else>Tidak ada keterangan tambahan</i>
            </bg-text>
          </bg-grid-item>
          <bg-grid-item :col="3">
            <div class="flex justify-end">
              <bg-button
                class="p-0"
                variant="tertiary-naked"
                @click="openReportAdditionalNotes"
              >
                Ubah
              </bg-button>
            </div>
          </bg-grid-item>
        </bg-grid>
      </bg-card>

      <div
        v-if="isApprovalButtonShow && accessApproveDisburse"
        class="detail-transfer-page__laporan__buttons"
      >
        <bg-button
          v-if="Number(data.revenueSection.total_transfer) > 0"
          variant="secondary"
          class="ml-16"
          :disabled="isApprovalDisabled"
          @click="openDisbursementApprovalModal"
          data-testid="approval-button"
        >
          {{ approvalText }}
        </bg-button>
        <bg-button
          v-else
          variant="secondary"
          class="ml-16"
          @click="openApproveAndSendReportModal"
          data-testid="approval-send-button"
        >
          Approve & Kirim Laporan
        </bg-button>
      </div>

      <div>
        <bg-button
          v-if="isTransferred || isNoTransferred"
          variant="primary"
          class="mr-16"
          @click="getReportPdf"
          :disabled="loading.generatingPdf"
          size="lg"
          data-testid="download-pdf-button"
        >
          {{
            loading.generatingPdf ? 'Loading...' : 'Unduh Laporan Pendapatan'
          }}
        </bg-button>
        <bg-button
          v-if="isTransferred"
          variant="secondary"
          size="lg"
          @click="handleGetBigflipImage"
          :loading="loading.downloadReceipt"
          data-testid="download-receipt-button"
        >
          Unduh Bukti Transfer
        </bg-button>
      </div>
    </template>

    <delete-transaksi-confirm-modal
      :is-open.sync="isModalOpen.deleteTransaksi"
      :invoice-id="selectedInvoiceId"
      @action-success-delete="fetchInvoiceAndFetchAmountProperties(pagination)"
    />

    <operational-modal
      :is-open.sync="isModalOpen.operational"
      :cost-data.sync="selectedCostData"
      :is-additional-income="isModalOpen.additionalIncome"
      @action-success="reloadTable('')"
    />

    <delete-operational-modal
      :is-open.sync="isModalOpen.deleteOperational"
      :cost-id="selectedCostIdToDelete"
      :is-additional-income="isModalOpen.additionalIncome"
      @action-success="reloadTable()"
    />

    <delete-additional-cost-modal
      :is-open.sync="isModalOpen.deleteAdditionalCost"
      :cost-id="selectedAdditionalCostIdToDelete"
      @action-success-delete="reloadTable('additional_tenant_cost')"
    />

    <disbursement-approval-modal
      :is-open.sync="isModalOpen.disbursementApproval"
      :approval-id="approvalId"
      :status.sync="selectedStatus"
      @action-success="handleGetDisbursementDetail(approvalId)"
    />

    <report-additional-notes-modal
      :is-open.sync="isReportAdditionalNotesOpen"
      :current-note="detailRincian.notes"
      @action-success="handleGetDisbursementDetail(approvalId)"
    />

    <approve-and-send-report-modal
      :is-open.sync="isModalOpen.approveAndSendReport"
      @action-success="handleGetDisbursementDetail(approvalId)"
    />

    <loading-overlay
      v-model="isCalculating"
      text="Sedang menghitung ulang..."
    />
  </div>
</template>

<script>
import {
  BgIcon,
  BgText,
  BgButton,
  BgSkeleton,
  BgListItem,
  BgCard,
  BgGrid,
  BgGridItem,
  BgInput,
  BgButtonIcon,
  BgTabs,
  BgTab,
  BgLink,
} from 'bangul-vue';

import dayjs from 'dayjs';

import { mapActions } from 'vuex';

import axios from 'axios';
import { rupiahFormatter, dateFormatterToDisplay } from 'Utils/formatter';
import { transferStatus } from 'Utils/disbursement';
import detailTransferApi, { endpoints } from '@admin_endpoints/detail-transfer';
import DetailTransferInfo from '@admin_organisms/DetailTransferInfo';
import TableActionMenu from '@admin_molecules/TableActionMenu';
import { encrypt, forceFileDownload } from 'Utils/etc';
import DetailTransferListTable from './components/DetailTransferListTable';
import DetailTransferDetailIncome from './components/DetailTransferDetailIncome';
import PartnershipModelAndAddOn from './components/PartnershipModelAndAddOn';

export default {
  name: 'DetailTransfer',

  components: {
    BgIcon,
    BgText,
    BgButton,
    BgSkeleton,
    DetailTransferListTable,
    DetailTransferDetailIncome,
    DetailTransferInfo,
    BgListItem,
    BgCard,
    BgGrid,
    BgGridItem,
    BgInput,
    TableActionMenu,
    BgButtonIcon,
    BgTabs,
    BgTab,
    BgLink,
    PartnershipModelAndAddOn,
    DeleteTransaksiConfirmModal: () =>
      import('./components/DeleteTransaksiConfirmModal'),
    OperationalModal: () => import('./components/OperationalModal'),
    DeleteOperationalModal: () => import('./components/DeleteOperationalModal'),
    DeleteAdditionalCostModal: () =>
      import('./components/DeleteAdditionalCostModal'),
    DisbursementApprovalModal: () =>
      import('@admin_molecules/DisbursementApprovalModal'),
    ReportAdditionalNotesModal: () =>
      import('./components/ReportAdditionalNotesModal'),
    DisbursementInvoiceList: () =>
      import('./components/DisbursementInvoiceList'),
    ApproveAndSendReportModal: () =>
      import('./components/ApproveAndSendReportModal'),
    LoadingOverlay: () => import('@molecules/LoadingOverlay'),
  },

  filters: {
    rupiah: value => {
      /* eslint-disable-next-line no-restricted-globals */
      if (value === '-' || !isFinite(value) || value === null) {
        return '-';
      }

      return rupiahFormatter(value);
    },
  },

  data() {
    return {
      data: {
        additionalIncomeList: [],
        additionalTenantCostList: [],
        costList: [],
        ota: {},
        revenueSection: {},
      },

      invoice: {},
      invoiceActiveTabIndex: 0,

      invoiceTabs: [
        {
          id: 'booking-tab',
          invoice: 'booking',
          title: 'Penyewa dari Booking',
        },
        {
          id: 'dbet-tab',
          invoice: 'dbet',
          title: 'Penyewa DBET',
        },
      ],

      isModalOpen: {
        additionalIncome: false,
        approveAndSendReport: false,
        deleteAdditionalCost: false,
        deleteOperational: false,
        deleteTransaksi: false,
        disbursementApproval: false,
        operational: false,
      },

      isCalculating: false,
      isLoadingTenant: false,
      isLoadingTenantArray: false,
      isReportAdditionalNotesOpen: false,
      isShowUnapprovePopup: false,

      loading: {
        additionalIncome: false,
        additionalTenantCost: false,
        disbursementDetail: false,
        downloadReceipt: false,
        generatingPdf: true,
        getInvoice: false,
        incomeTransferBreakdown: false,
        operationalCost: false,
        ota: false,
      },

      pagination: {
        limit: 10,
        offset: 0,
        page: 1,
        totalData: 0,
        totalPage: 1,
      },

      selectedAdditionalCostIdToDelete: null,
      selectedCostData: {},
      selectedCostIdToDelete: null,
      selectedInvoiceId: null,
      selectedStatus: '',
      showTenantFeePopup: false,

      table_header: {
        income: [
          'Nama Pendapatan',
          'Harga Satuan',
          'Kuantitas',
          'Total Pendapatan',
          'Action',
        ],
        operasional: [
          'Nama Biaya',
          'Harga Satuan',
          'Kuantitas',
          'Total Biaya',
          'Action',
        ],
        ota: [
          'Nama Penyewa',
          'Nomor Kamar',
          'Check-In',
          'Check-Out',
          'Booking ID',
          'Jumlah Ditransfer',
        ],
        tenant: [
          'Detail Penyewa',
          'Biaya Tambahan',
          'Asal Biaya',
          'Nomor Invoice',
          'Periode Awal',
          'Periode Akhir',
          'Transfer ke Pemilik',
          'Action',
        ],
      },
    };
  },

  computed: {
    accessApproveDisburse() {
      return this.$store.getters.xCheckUserPermission('approve-disburse');
    },
    accessDetailDisbursement() {
      return this.$store.getters.xCheckUserPermission('detail-disbursement');
    },
    accessEditTransactionDisbursement() {
      return this.$store.getters.xCheckUserPermission(
        'edit-transaction-disbursement'
      );
    },
    currentApprovalId() {
      return this.$route.params.approval_id;
    },
    isTransferred() {
      const { TRANSFERRED, AUTO_TRANSFERRED } = transferStatus;
      return this.invoiceData.data.transfer_status === TRANSFERRED ||
        this.invoiceData.data.transfer_status === AUTO_TRANSFERRED;
    },
    isNoTransferred() {
      const { NO_TRANSFERRED } = transferStatus;
      return this.invoiceData.data.transfer_status === NO_TRANSFERRED;
    },
    unapproveNote() {
      return this.detailRincian.notes || '';
    },
    invoiceData() {
      return this.$store.state.detailTransfer.data;
    },
    invoiceList() {
      return this.invoiceData.data.invoices_list || [];
    },
    invoiceIdArray() {
      const arrays = [];

      this.invoiceList.forEach(element => {
        arrays.push(element.invoice_id);
      });

      return arrays;
    },
    approvalId() {
      return this.$route.params.approval_id;
    },
    detailRincian() {
      const dataRincian = {
        approval_id: this.invoiceData.data.approval_id,
        property_name: this.invoiceData.data.property_name,
        property_id: this.invoiceData.data.property_id,
        status: this.invoiceData.data.status || '',
        status_color:
          this.invoiceData.data.status === 'unapproved' ? 'white' : 'green',
        status_date: this.invoiceData.data.approved_date,
        transfer_status: this.invoiceData.data.transfer_status || '',
        transfer_status_color:
          /* eslint-disable no-nested-ternary */
          this.invoiceData.data.transfer_status === 'transferred' || this.invoiceData.data.transfer_status === 'auto_transferred'
            ? 'green'
            : this.invoiceData.data.transfer_status === 'waiting'
            ? 'yellow'
            : this.invoiceData.data.transfer_status === 'failed'
            ? 'red'
            : 'white',
        /* eslint-enable no-nested-ternary */
        revenue_model_name: this.invoiceData.data.revenue_model_name,
        schedule_date: this.invoiceData.data.scheduled_date,
        cutoff_start: this.invoiceData.data.cutoff_start,
        cutoff_end: this.invoiceData.data.cutoff_end,
        notes: this.invoiceData.data.notes,
        is_adp: this.invoiceData.data.is_adp,
        is_income_guarantee: this.invoiceData.data.is_income_guarantee,
        is_hybrid: this.invoiceData.data.is_hybrid,
        adp_type: this.invoiceData.data.adp_type,
        income_guarantee_type: this.invoiceData.data.income_guarantee_type,
        original_revenue_model_name: this.invoiceData.data
          .original_revenue_model_name,
        transferred_at: this.invoiceData.data.transferred_at,
        disbursement_id: this.invoiceData.data.disbursement_id,
      };
      return dataRincian;
    },
    xCostData() {
      return this.$store.state.detailTransfer.cost;
    },
    sumTotalCost() {
      return this.data.costList.reduce((total, cost) => {
        const sum = parseFloat(Number(cost.total_amount));
        return total + (!Number.isNaN(sum) ? sum : 0);
      }, 0);
    },
    sumTotalAdditionalIncome() {
      return this.data.additionalIncomeList.reduce((total, cost) => {
        const sum = parseFloat(Number(cost.amount) * Number(cost.qty));
        return total + (!Number.isNaN(sum) ? sum : 0);
      }, 0);
    },
    sumTotalAdditionalTenantCost() {
      return this.data.additionalTenantCostList.reduce((total, cost) => {
        const sum = parseFloat(Number(cost.total_transferred));
        return total + (!Number.isNaN(sum) ? sum : 0);
      }, 0);
    },
    sumTotalOta() {
      return this.data.ota.data.reduce((total, item) => {
        const sum = parseFloat(Number(item.amount));
        return total + (!Number.isNaN(sum) ? sum : 0);
      }, 0);
    },
    tenantFeeList() {
      return this.$store.state.detailTransfer.tenantFee;
    },
    approvalText() {
      switch (this.detailRincian.status) {
        case 'unapproved':
          return 'Konfirmasi';

        case 'approved':
          return 'Batalkan Konfirmasi';
        
        case 'approved_by_system':
          return 'Batalkan Konfirmasi';

        default:
          return '';
      }
    },
    isUnapproved() {
      return this.detailRincian.status === 'unapproved';
    },
    isApprovalDisabled() {
      const { PROCESSING, TRANSFERRED } = transferStatus;
      return [PROCESSING, TRANSFERRED].includes(
        this.detailRincian.transfer_status
      );
    },
    currentTime() {
      return dayjs(new Date()).format('YYYY-MM-DDTHHmmss');
    },
    isApprovalButtonShow() {
      return !this.isTransferred && !this.isNoTransferred && this.detailRincian.status !== 'approved_by_system';
    },
    showRecalculateButton() {
      const { WAITING } = transferStatus;
      return (
        this.isUnapproved && this.invoiceData.data.transfer_status === WAITING
      );
    },
    selectedInvoiceList() {
      const isHybrid = this.invoice.is_hybrid;
      if (typeof isHybrid !== 'undefined' && !this.invoice.is_hybrid) {
        return this.invoiceTabs.reduce((acc, cur) => {
          const invoices = [...acc, ...(this.invoice[cur.invoice] || [])];
          return invoices;
        }, []);
      }

      const selectedTab = this.invoiceTabs[this.invoiceActiveTabIndex];

      return this.invoice[selectedTab.invoice] || [];
    },

    invoiceType() {
      return {
        mamikos: 'Invoice Utama',
        manual: 'Input Manual PMS',
        manual_invoice: 'Invoice Manual',
      };
    },
  },

  watch: {
    xCostData(lists) {
      this.data.costList = lists.map(cost => ({ ...cost }));
    },
  },

  created() {
    /*
      FIXME: Find a proper solution for checking if the userhave the permission to open this page instead of this line
      if (!this.accessDetailDisbursement) {
        this.$router.push('/disbursement');
      }
    */
    this.loading.generatingPdf = true;
    this.fetchInvoiceList(this.pagination);
    this.handleGetDisbursementDetail(this.$route.params.approval_id);
    this.handleFecthGetOperationalCost({ approval_id: this.approvalId });
    this.handleGetAdditionalIncome();
    this.handleGetDisbursementAmountProperties();
    this.handleGetAdditionalTenantCost();
    this.fetchGetDisbursedOta();
  },

  methods: {
    ...mapActions({
      xHandleGetDisbursementDetail: 'handleGetDisbursementDetail',
      xGetTenantFee: 'getTenantFee',
      xGetOperationalCost: 'getOperationalCost',
    }),

    rupiahFormatter(value) {
      return rupiahFormatter(value);
    },

    handleToggleUnapprovePopup() {
      this.isShowUnapprovePopup = !this.isShowUnapprovePopup;
    },

    calculateAmount(index) {
      return this.tenantFeeArray[index].reduce((a, b) => ({
        amount: a.amount + b.amount,
      })).amount;
    },

    dateFormatter(time, full = false) {
      const formatting = full ? 'DD MMMM YYYY' : 'DD/MM/YYYY';
      return time ? dateFormatterToDisplay(time, formatting) : '-';

    },

    handleOpenOperationalModal(cost = {}, additional = false) {
      this.selectedCostData = { ...cost };
      this.isModalOpen.operational = true;
      this.isModalOpen.additionalIncome = additional;
    },

    handleOpenDeleteOperationalModal(costId, additional = false) {
      this.selectedCostIdToDelete = costId;
      this.isModalOpen.deleteOperational = true;
      this.isModalOpen.additionalIncome = additional;
    },

    handleOpenDeleteAdditionalCostModal(costId) {
      this.selectedAdditionalCostIdToDelete = costId;
      this.isModalOpen.deleteAdditionalCost = true;
    },

    handleToggleTenantFee(id) {
      this.tenantFeePopupId = id;
      this.handleFetchTenantFee(id);
      this.showTenantFeePopup = !this.showTenantFeePopup;
    },

    async fetchInvoiceList() {
      this.loading.getInvoice = true;

      try {
        const { data } = await detailTransferApi.getInvoiceList(
          this.approvalId
        );

        if (data.data) {
          this.invoice = data.data;
        }
      } catch (error) {
        console.error(error);
        this.$toast.show('Gagal saat memuat data invoice.');
      } finally {
        this.loading.getInvoice = false;
      }
    },

    async handleGetDisbursementDetail(approvalId) {
      this.loading.disbursementDetail = true;

      try {
        await this.xHandleGetDisbursementDetail(approvalId);
        this.loading.disbursementDetail = false;
      } catch (error) {
        console.error(error);
      }
    },

    async handleFetchTenantFee(invoiceId) {
      this.isLoadingTenant = true;
      try {
        await this.xGetTenantFee({ invoiceId });
        this.isLoadingTenant = false;
      } catch (error) {
        console.error(error);
      }
    },

    getTenantArrayList() {
      const result = this.tenantFeeArrayList
        .map(fee => fee.data)
        .map(itemFee => itemFee.data);
      this.tenantFeeArray = result;
      this.isLoadingTenant = false;
    },

    handleEditTransaction(invoice) {
      if (invoice.invoice_type === 'mamikos') {
        this.$router.push(
          `/disbursement/edit-mamikos-transaction/${this.currentApprovalId}/${invoice.id}`
        );
      } else if (invoice.invoice_type === 'manual') {
        this.$router.push(
          `/disbursement/edit-manual-transaction/${this.currentApprovalId}/${invoice.id}`
        );
      }
    },

    handleDeleteTransaksi(invoice) {
      this.selectedInvoiceId = invoice.id;
      this.isModalOpen.deleteTransaksi = true;
    },

    handleGoToAdditionalCost(invoice) {
      this.$router.push({
        name: 'disbursement.additional-cost-detail',
        params: {
          approval_id: this.approvalId,
          disbursement_invoice_id: invoice.id,
        },
      });
    },

    openDisbursementApprovalModal() {
      this.selectedStatus = this.detailRincian.status;
      this.isModalOpen.disbursementApproval = true;
    },

    fetchInvoiceAndFetchAmountProperties(pagination) {
      this.fetchInvoiceList(pagination);
      this.handleGetDisbursementAmountProperties();
    },

    getReportPdf() {
      const zeroDisbursement =
        Number(this.data.revenueSection.total_transfer) === 0;
      const keyName = {
        date: zeroDisbursement ? 'cutoff_start' : 'transferred_at',
        id: zeroDisbursement ? 'approval_id' : 'disbursement_id',
        zocc: zeroDisbursement ? '/zocc' : '',
      };

      const year = dayjs(this.detailRincian[keyName.date]).year();
      const month = dayjs(this.detailRincian[keyName.date]).month();
      const encrypted = encrypt(
        `${this.detailRincian[keyName.id]}`,
        process.env.VUE_APP_OWNER_CP_DISBURSEMENT_REPORT_SECRET_IV,
        process.env.VUE_APP_OWNER_CP_DISBURSEMENT_REPORT_SECRET_KEY
      );

      const url = `${process.env.VUE_APP_API_URL}/laporan-pendapatan${
        keyName.zocc
      }/${year}/${month}/${btoa(encrypted)}.pdf`;

      window.open(url, '_blank');
    },

    openReportAdditionalNotes() {
      this.isReportAdditionalNotesOpen = true;
    },

    reloadTable(table) {
      if (this.isModalOpen.additionalIncome) {
        this.handleGetAdditionalIncome();
      } else if (table === 'additional_tenant_cost') {
        this.handleGetAdditionalTenantCost();
      } else {
        this.handleFecthGetOperationalCost({ approval_id: this.approvalId });
      }
      this.handleGetDisbursementAmountProperties();
    },

    openApproveAndSendReportModal() {
      this.isModalOpen.approveAndSendReport = true;
    },

    async recalculateData() {
      let isCalculateSuccess = true;
      this.isCalculating = true;

      try {
        const {
          data: { status },
        } = await detailTransferApi.recalculate(this.approvalId);
        isCalculateSuccess = status || false;
      } catch (_) {
        isCalculateSuccess = false;
      }

      this.isCalculating = false;

      if (isCalculateSuccess) {
        this.invoiceActiveTabIndex = 0;
        this.fetchInvoiceList(this.pagination);
        this.handleGetDisbursementDetail(this.$route.params.approval_id);
        this.handleFecthGetOperationalCost({ approval_id: this.approvalId });
        this.handleGetAdditionalIncome();
        this.handleGetDisbursementAmountProperties();
      } else {
        this.$toast.show('Terjadi kesalahan saat mencoba recalculate data.');
      }
    },

    async handleFecthGetOperationalCost({ approval_id }) {
      this.loading.operationalCost = true;
      this.loading.incomeTransferBreakdown = true;

      try {
        await this.xGetOperationalCost({ approval_id });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading.operationalCost = false;
        this.loading.generatingPdf = false;
      }
    },

    async handleGetDisbursementAmountProperties() {
      this.loading.incomeTransferBreakdown = true;
      const response = await endpoints.getDisbursementAmountProperties(
        this.currentApprovalId
      );

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details);
      } else {
        this.data.revenueSection = response.data;
      }

      this.loading.incomeTransferBreakdown = false;
    },

    async handleGetAdditionalIncome() {
      this.loading.additionalIncome = true;
      const response = await endpoints.getAdditionalIncome(
        this.currentApprovalId
      );
      this.loading.additionalIncome = false;

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details);
      } else {
        this.data.additionalIncomeList = [...response.data];
      }
    },

    async handleGetBigflipImage() {
      this.loading.downloadReceipt = true;
      const response = await endpoints.getBigflipImage(this.currentApprovalId);
      this.loading.downloadReceipt = false;

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details);
      } else {
        const imgUrl = response.data;
        const currentTime = dayjs(new Date()).format('YYYY-MM-DDTHHmmss');
        const filename = `Bukti Transfer_${this.detailRincian.property_name}_${currentTime}.png`;

        await axios({
          method: 'get',
          url: imgUrl,
          responseType: 'arraybuffer',
        })
          .then(res => {
            forceFileDownload(res.data, filename);
            this.$toast.show('Bukti Transfer berhasil diunduh');
          })
          .catch(() => console.error('error occured'));
      }
    },

    async handleGetAdditionalTenantCost() {
      this.loading.additionalTenantCost = true;
      const response = await endpoints.getAdditionalTenantCost(
        this.currentApprovalId
      );
      this.loading.additionalTenantCost = false;

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details);
      } else {
        this.data.additionalTenantCostList = 
          response.data.filter(item => item.total_transferred);
      }
    },

    async fetchGetDisbursedOta() {
      this.loading.ota = true;

      const response = await endpoints.getDisbursedOta(
        this.currentApprovalId
      );

      this.loading.ota = false;

      if (response) {
        this.data.ota = response;
      }
    }
  },
};
</script>

<style src="./DetailTransfer.scss" lang="scss" scoped />
