import jpData from './data/jp.json';
import adpData from './data/adp.json';
import { capitalize } from './typography';

const makeNewTransform = str => {
  const clean = str.replace(/_/gi, ' ');

  return capitalize(clean);
};

/**
 *
 * @param {String|null} type
 * @param {any} defaultValue - optional param
 * @returns {String}
 */
export const jp = (type, defaultValue) => {
  if (!type) return typeof defaultValue !== 'undefined' ? defaultValue : type;

  const transformed = jpData[type.toUpperCase()];

  return transformed || makeNewTransform(type);
};

export const adp = (type, defaultValue) => {
  if (!type) return typeof defaultValue !== 'undefined' ? defaultValue : type;

  const transformed = adpData[type.toUpperCase()];

  return transformed || makeNewTransform(type);
};
