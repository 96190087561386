<template>
  <div class="detail-transfer-page-detail-income">
    <bg-grid v-if="isLoading">
      <bg-grid-item>
        <bg-skeleton width="100%" height="150px" />
      </bg-grid-item>
      <bg-grid-item>
        <bg-skeleton width="100%" height="150px" />
      </bg-grid-item>
    </bg-grid>

    <bg-grid v-else>
      <bg-grid-item>
        <bg-card class="mb-24">
          <bg-text size="heading-4" class="mb-24">
            Persentase Pendapatan & Add On
          </bg-text>
          <div v-if="data.revenue_model === 'commission'" class="split">
            <bg-text size="body-2">
              Persentase pendapatan pemilik dari booking
            </bg-text>
            <bg-text size="body-2">
              {{ data.percentage_income | percentage }}
            </bg-text>
          </div>
          <div v-if="data.is_hybrid" class="split">
            <bg-text size="body-2">
              Persentase pendapatan pemilik dari penyewa DBET
            </bg-text>
            <bg-text size="body-2">
              {{ data.percentage_dbet | percentage }}
            </bg-text>
          </div>
          <div class="split">
            <bg-text size="body-2">Jumlah JP</bg-text>
            <bg-text size="body-2">
              {{ rupiahFormatter(data.jp_amount) }}
            </bg-text>
          </div>
        </bg-card>

        <bg-card>
          <bg-text size="heading-4" class="mb-24">
            Pendapatan Kotor Penjualan Kamar
          </bg-text>
          <div class="split">
            <bg-text size="body-2">Total penjualan kamar</bg-text>
            <bg-text size="body-2">
              {{ rupiahFormatter(data.total_income) }}
            </bg-text>
          </div>
          <div
            v-if="data.revenue_model === 'commission'"
            class="split split--with-border"
          >
            <bg-text size="body-2">Potongan bagi hasil</bg-text>
            <bg-text size="body-2">
              {{ rupiahFormatter(data.profit_sharing, true) }}
            </bg-text>
          </div>
          <div
            v-if="data.is_hybrid"
            class="split split--with-border">
            <bg-text size="body-1">
              Pendapatan kotor penjualan kamar penyewa dari booking
            </bg-text>
            <bg-text size="body-1">
              {{ rupiahFormatter(data.gross_income_booking) }}
            </bg-text>
          </div>
          <div
            v-if="data.is_hybrid"
            class="split"
          >
            <bg-text size="body-2">Total penjualan kamar penyewa DBET</bg-text>
            <bg-text size="body-2">
              {{ rupiahFormatter(data.total_income_dbet) }}
            </bg-text>
          </div>
          <div
            v-if="data.is_hybrid"
            class="split split--with-border"
          >
            <bg-text size="body-2">Potongan bagi hasil</bg-text>
            <bg-text size="body-2">
              {{ rupiahFormatter(data.profit_sharing_dbet, true) }}
            </bg-text>
          </div>
          <div v-if="data.is_hybrid" class="split split--with-border">
            <bg-text size="body-1">
              Pendapatan kotor penjualan kamar penyewa DBET
            </bg-text>
            <bg-text size="body-1">
              {{ rupiahFormatter(data.gross_income_dbet) }}
            </bg-text>
          </div>
          <div class="split split--with-border">
            <bg-text size="body-1">Pendapatan kotor penjualan kamar</bg-text>
            <bg-text size="body-1">
              {{ rupiahFormatter(data.gross_income1) }}
            </bg-text>
          </div>
          <div class="split mb-0">
            <bg-text size="body-1">
              Pendapatan kotor berdasarkan add on JP
              <bg-tooltip
                text="Pendapatan kotor penjualan kamar = Total penjualan kamar * Persentase pendapatan pemilik"
                trigger="hover"
                :closable="false"
                v-if="false"
              >
                <bg-icon name="information-round" title="Pendapatan Kotor" />
              </bg-tooltip>
            </bg-text>
            <bg-text size="body-1">
              {{ rupiahFormatter(data.gross_income2) }}
            </bg-text>
          </div>
        </bg-card>
      </bg-grid-item>

      <bg-grid-item>
        <bg-card class="mb-24">
          <bg-text size="heading-4" class="mb-24">
            Pendapatan Bersih Properti
          </bg-text>
          <div class="split split--with-border">
            <bg-text size="body-2">
              Pendapatan kotor berdasarkan add on JP
            </bg-text>
            <bg-text size="body-2">
              {{ rupiahFormatter(data.gross_income2) }}
            </bg-text>
          </div>
          <div class="split">
            <bg-text size="body-2">Total biaya tambahan penyewa</bg-text>
            <bg-text size="body-2">
              {{ rupiahFormatter(data.additional_cost) }}
            </bg-text>
          </div>
          <div v-if="isOta" class="split">
            <bg-text size="body-2">Total penjualan kamar harian</bg-text>
            <bg-text size="body-2">
              {{ rupiahFormatter(data.total_ota) }}
            </bg-text>
          </div>
          <div class="split">
            <bg-text size="body-2">Total tambahan pendapatan</bg-text>
            <bg-text size="body-2">
              {{ rupiahFormatter(data.additional_income) }}
            </bg-text>
          </div>
          <div class="split split--with-border">
            <bg-text size="body-2">Total biaya pengurangan operasional</bg-text>
            <bg-text size="body-2">
              {{ rupiahFormatter(data.operational_cost, true) }}
            </bg-text>
          </div>
          <div class="split split--with-border">
            <bg-text size="body-1">Pendapatan bersih properti</bg-text>
            <bg-text size="body-1">
              {{ rupiahFormatter(data.net_income) }}
            </bg-text>
          </div>
          <div class="split mb-0">
            <bg-text size="title-4">
              Total transfer pendapatan ke pemilik
            </bg-text>
            <bg-text size="title-4">
              {{ rupiahFormatter(data.total_transfer) }}
            </bg-text>
          </div>
        </bg-card>

        <bg-card>
          <header class="split">
            <bg-text size="heading-4">Tujuan Transfer</bg-text>
            <bg-link class="same-height">Ubah</bg-link>
          </header>
          <bg-text size="body-1" class="mb-8">{{ data.bank_name }}</bg-text>
          <bg-text size="body-1" class="mb-8">{{ data.bank_account }}</bg-text>
          <bg-text size="body-2">a/n {{ data.bank_owner }}</bg-text>
        </bg-card>
      </bg-grid-item>
    </bg-grid>
  </div>
</template>

<script>
import {
  BgCard,
  BgText,
  BgGrid,
  BgGridItem,
  BgLink,
  BgIcon,
  BgTooltip,
  BgSkeleton
} from 'bangul-vue';

import { rupiahFormatter } from 'Utils/formatter';

export default {
  name: 'DetailTransferDetailIncome',

  components: {
    BgCard,
    BgText,
    BgGrid,
    BgGridItem,
    BgLink,
    BgIcon,
    BgTooltip,
    BgSkeleton
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    isOta: {
      type: Boolean,
      default: false,
    }
  },

  filters: {
    percentage(value) {
      if (!value) return '-';

      const makeTheNumberReadable = Number(Number(value).toFixed(2)) * 100;

      return `${makeTheNumberReadable}%`;
    },
  },

  methods: {
    rupiahFormatter(value = 0, negative = false) {
      let isNegative = false;
      let tempValue = value;
      if (value < 0) {
        isNegative = true;
        tempValue = Math.abs(value);
      }

      return `${negative || isNegative ? '-' : ''}${rupiahFormatter(
        Math.round(tempValue)
      )}`;
    },
  },
};
</script>

<style src="./DetailTransferDetailIncome.scss" lang="scss" scoped></style>
