<template>
  <section class="partnership-model-and-add-on">
    <bg-card>
      <div class="partnership-model-card__title">
        <bg-text size="heading-5">Model Kerja Sama dan Add On</bg-text>
      </div>

      <div class="partnership-model-card__content">
        <bg-grid>
          <bg-grid-item :col="4" class="mb-0">
            <div class="partnership-model-card__info">
              <bg-text class="text-muted mb-16" size="title-5"
                >Basic Kerja Sama</bg-text
              >
              <div class="partnership-model-card__icon mb-16">
                <bg-icon name="tenant-bill" size="xl" />
              </div>
              <bg-text size="title-3" data-testid="partnership-model">
                {{ modelText }}
              </bg-text>
            </div>
          </bg-grid-item>
          <bg-grid-item :col="4" class="mb-0">
            <div class="partnership-model-card__info">
              <bg-text class="text-muted mb-16" size="title-5"
                >Jaminan Pendapatan</bg-text
              >
              <div class="partnership-model-card__icon mb-16">
                <bg-icon name="saldo" size="xl" />
              </div>
              <bg-text size="title-3" data-testid="add-on-jp">
                {{ jpText }}
              </bg-text>
            </div>
          </bg-grid-item>
          <bg-grid-item :col="4" class="mb-0">
            <div class="partnership-model-card__info">
              <bg-text class="text-muted mb-16" size="title-5"
                >Advance Deposit Program</bg-text
              >
              <div class="partnership-model-card__icon mb-16">
                <bg-icon name="article-news" size="xl" />
              </div>
              <bg-text size="title-3" data-testid="add-on-adp">
                {{ adpText }}
              </bg-text>
            </div>
          </bg-grid-item>
        </bg-grid>
      </div>
    </bg-card>
  </section>
</template>

<script>
import { BgCard, BgIcon, BgText, BgGrid, BgGridItem } from 'bangul-vue';
import * as transformer from 'Utils/transformer';
import { capitalize } from 'Utils/typography';

export default {
  name: 'PartnershipModelAndAddOn',

  components: {
    BgCard,
    BgIcon,
    BgText,
    BgGrid,
    BgGridItem,
  },

  props: {
    model: {
      type: String,
      default: '',
    },
    adpType: {
      type: String,
      default: null,
    },
    jpType: {
      type: String,
      default: null,
    },
  },

  computed: {
    adpText() {
      return transformer.adp(this.adpType, 'Tidak Ada');
    },
    jpText() {
      return transformer.jp(this.jpType, 'Tidak Ada');
    },
    modelText() {
      return this.model
        ? capitalize(this.model)
        : /* istanbul ignore next */ '';
    },
  },
};
</script>

<style lang="scss" src="./PartnershipModelAndAddOn.scss"></style>
